import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Spinner,
  ListGroup,
  Badge,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./AllCommunityPosts.css";
import { motion } from "framer-motion";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import blogCategory from "../../utils/blogCategory.json";

const blogItemVariant = {
  hover: {
    fontWeight: "bold",
    transition: {
      type: "spring",
      stiffness: 300,
      yoyo: Infinity, // We can give any value like 100 etc
    },
  },
};

const AllCommunityPosts = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterCategory, setFilterCategory] = useState(null);
  const navigate = useNavigate();

  const [total, setTotal]= useState(0);
  const [page, setPage]= useState(1);
  const limit=6;

  // const dispatch= useDispatch();
  // const state= useSelector((state)=>state.allblog);
  // console.log("State",state)

  const fetchPosts = async () => {
    try {
      const response = await axios.get(`/api/admin/community?page=${page}&limit=${limit}`);
      setPosts(response.data.posts);
      setTotal(response.data.total);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Posts:", error);
    }
  };

  useEffect(() => {
    // dispatch(fetchAllBlog());
    // const fetchBlogs = async () => {
    //   try {
    //     const response = await axios.get("/api/blogs");
    //     setBlogs(response.data);
    //     setIsLoading(false);
    //   } catch (error) {
    //     console.error("Error fetching Blogs:", error);
    //   }
    // };
    
    fetchPosts();
    // }, [isLoggedIn, blogs]);
  }, [page]);

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (posts.length === 0) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <div>Posts not found.</div>
      </Container>
    );
  }

  
  const prePage = () => {
    if(page>1){
      setPage(page-1);
    }
  };
  
  const changeCPage = (id) => {
    setPage(id);
  };
  
  const nextPage = () => {
    if(page<totalPages){
      setPage(page+1);
    }
  };
  
  const totalPages= Math.ceil(total/limit);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);

  const deleteCommunityPost= async (id)=>{
    const confirmDiscard = window.confirm(
      "Are you sure you want to delete this community post?"
    );
    if (confirmDiscard) {
    try {
        const response= await axios.delete(`/api/admin/deletecommunitypost/${id}`);
        toast.success("Post deleted successfully!!");
        fetchPosts();
    } catch (error) {
        console.error("Error when deleting community post");
        toast.error("Error when deleting post");
    }
  }
  }
  
  return (
    <section className="newpage-section">
      <Container>
        <ToastContainer />
        <h3 className="page-title">Manage Community Posts</h3>
        <div className="heading-underline"></div>
        <i>
          Showing total results: {total}, Page {page} of {totalPages}
        </i>
        <div>
          <b>Filter by category: </b>
          <Select
            className="react-select-dropdown m-1"
            defaultValue={filterCategory}
            onChange={setFilterCategory}
            options={blogCategory}
          />
          <Button variant="success" size="sm" onClick={()=>null}>Search</Button>
        </div>

        {/* {state.isLoading && <div>Loading..</div>}
        {!state.isLoading && state.isError && <div>Error..{state.error}</div>}
        {!state.isLoading && state.data.length?(
          <ul>
            {
              state.data.map(blog=>(
                <li>{blog.title}</li>
              ))
            }
          </ul>
        ):null} */}

        {posts?.length === 0 ? (
          <div>No posts found</div>
        ) : (
          <>
            <Row className="m-3">
              {posts?.map((post) => (
                <Col md={4}>
                  <div key={post.communityPostSlug} className="mb-2 border blogitem">
                    <motion.div
                      variants={blogItemVariant}
                      whileHover="hover"
                      className="row align-items-center"
                    >
                      
                        <Card className="blogcard">
                          <div class="blogcard-container">
                            <img
                              src="/carousel_img1.png"
                              alt="Image"
                              className="blogcard-container-img"
                            />
                            <div class="blogcard-container-text">{post.communityPostCategory}</div>
                          </div>
                          <Link
                        to={`${process.env.REACT_APP_BLOGGERSPACE}/community/post/${post.communityPostId}/${post.communityPostSlug}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                          <Card.Body className="">
                            <h6>{post.communityPostTopic}</h6>
                            {/* <div className="underline"></div> */}
                            {post.communityPostTags &&
                              post.communityPostTags.map((tag) => (
                                <Badge
                                  key={tag}
                                  pill
                                  bg="secondary"
                                  className="mx-1"
                                >
                                  {tag}
                                </Badge>
                              ))}
                            <p>
                              <i className="text-muted">
                                Author: {post.communityPostAuthor.userName}
                              </i>
                              <br />
                              <i className="text-muted">
                                Last Updated: {post.lastUpdatedAt.slice(11, 19)}
                                , {post.lastUpdatedAt.slice(0, 10)}
                              </i>
                            </p>
                          </Card.Body>
                          </Link>
                          <Card.Footer>
                            <Link className="btn bs-button mx-2" to={`${process.env.REACT_APP_BLOGGERSPACE}/community/post/${post.communityPostId}/${post.communityPostSlug}`}>
                                View Post
                            </Link>
                            <Button variant="danger" onClick={()=>deleteCommunityPost(post._id)}>
                                Delete post
                            </Button>
                          </Card.Footer>
                        </Card>
                      
                    </motion.div>
                  </div>
                </Col>
              ))}

              <nav>
                <ul className="pagination">
                  <li className="page-item">
                    <button className="page-link" onClick={prePage}>
                      Prev
                    </button>
                  </li>
                  {numbers.slice(0, 3).map((n, i) => (
                    <li
                      className={`page-item ${
                        page === n ? "active" : ""
                      }`}
                      key={i}
                    >
                      <button
                        className="page-link"
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button className="page-link">
                      ...
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => changeCPage(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={nextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>

              {/* <Link to={"/sitemap"}>Show more</Link> */}
            </Row>
          </>
        )}
      </Container>
    </section>
  );
};

export default AllCommunityPosts;
